%with-cursor-without-outline {
  cursor: pointer;
  outline: none;
}

@mixin input-type-number-clear {
  outline: none;
  -moz-appearance: textfield !important; // fix for remove default arrows

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
    margin: 0; // Apparently some margin are still there even though it's hidden /
  }
}

;@import "sass-embedded-legacy-load-done:2185";