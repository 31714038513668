.payment-systems {
  @include xy-grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: rem-calc(5);

  .label {
    margin: rem-calc(15 20);
    text-align: center;
    color: $black;
    line-height: 1;
    font: {
      size: rem-calc(13);
    }
  }

  .list {
    @include xy-grid;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: rem-calc(5 10);

      &:nth-child(1) {
        .payment-logo {
          width: rem-calc(82);
        }
      }

      &:nth-child(2) {
        .payment-logo {
          width: rem-calc(60);
        }
      }

      &:nth-child(3) {
        .payment-logo {
          width: rem-calc(80);
        }
      }

      &:nth-child(4) {
        margin-right: 0;

        .payment-logo {
          width: rem-calc(132);
        }
      }
    }
  }
}

@include breakpoint(small only) {
  .payment-systems {
    .label {
      width: 100%;
    }
  }
}

@include breakpoint(large up) {
  .payment-systems {
    width: auto;
    margin: 0 0 0 auto;
  }
}


@include breakpoint(xxlarge up) {
  .layout-footer {
    .payment-systems {
      @include xy-grid;
      justify-content: flex-end;
      align-items: center;

      .label {
        margin-bottom: 0;
        margin-right: rem-calc(15);
      }

      .list {
        li {
          margin: rem-calc(0 15);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2318";