
.bullet-list {
  margin-left: 0;
  list-style: none;

  li {
    position: relative;
    min-height: rem-calc(19);
    margin-bottom: rem-calc(13);
    padding-left: rem-calc(35);
  }

  .icon {
    position: absolute;
    top: rem-calc(2);
    left: 0;
    height: rem-calc(20);
    width: rem-calc(20);
  }
}

@include breakpoint(small down) {
  body {
    font: {
      size: rem-calc(14);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2073";