.contact-phone {
  text-align: center;
  line-height: 1;

  &__label {
    margin-bottom: rem-calc(7);
    color: $white;
    font: {
      size: rem-calc(14);
    }
    opacity: 0.7;
  }

  &__value {
    color: $white;
    font: {
      size: rem-calc(20);
      weight: 500;
    }
  }
}

@include breakpoint(medium up) {
  .contact-phone {
    @include xy-cell-base;
    text-align: left;
  }
}

;@import "sass-embedded-legacy-load-done:2314";