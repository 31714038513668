
.social-networks {
  @include xy-grid;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: rem-calc(0 15);
  }

  a {
    position: relative;
    display: block;
    height: rem-calc(36);
    width: rem-calc(36);
    background: #f3f4f5;
    border-radius: rem-calc(12);
    transition: background 0.25s ease-in-out;

    &:hover {
      .icon {
        fill: $white;
      }
    }
  }

  .icon {
    @include absolute-center;
    transition: fill 0.25s ease-in-out;
  }

  .twitter {
    .icon {
      width: rem-calc(18);
      fill: #03a9f4;
    }

    &:hover {
      background: #03a9f4;
    }
  }

  .facebook {
    .icon {
      width: rem-calc(9);
      fill: #1976D2;
    }

    &:hover {
      background: #1976D2;
    }
  }

  .instagram {
    .icon {
      width: rem-calc(19);
    }

    .icon-hover {
      fill: $white;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    &:hover {
      background: linear-gradient(455deg, rgba(255, 221, 85, 1) 0%, rgba(255, 84, 62, 1) 50%, rgba(200, 55, 171, 1) 100%);

      .icon-hover {
        opacity: 1;
      }
    }
  }
}

@include breakpoint(small only) {
  .social-networks {
    // margin-top: rem-calc(10);
  }
}

@include breakpoint(medium up) {
  .social-networks {
    // margin-left: rem-calc(15);
  }
}

@include breakpoint(xlarge up) {
  .social-networks {
    li {
      margin-right: rem-calc(10);
      margin-left: rem-calc(10);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2319";