@import 'header/page_navigation';

.layout-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: transparent;

  @at-root {
    .delayed &,
    .was-scrolled & {
      transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    }
  }

  @at-root .header-repainted & {
    background: $white;
    box-shadow: 0 0 10px 0 rgba($black, 0.3);
  }

  .content-container {
    @include xy-grid-container(rem-calc(1440));
    @include xy-grid;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: rem-calc(46);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    // transition: padding 0.25s linear;
  }

  .logo {
    img {
      width: rem-calc(75);
      // transition: width 0.25s linear
    }
  }

  .page-navigation.header__page-navigation {
    .close-button {
      @include close-button;
      top: 0;
      right: 0;
      height: rem-calc(40);
      width: rem-calc(40);
      outline: none;

      .icon {
        @include absolute-center;
        height: rem-calc(25);
        width: rem-calc(25);
      }

      &:hover, &:active {
        color: white;
      }
    }
  }

  .contact-phone {
    @include xy-grid;
    align-items: center;
    margin: rem-calc(20 16);
    color: $white;
    font: {
      size: rem-calc(16);
    }

    &__label {
      margin-right: rem-calc(5);
    }

    &__value {
      color: $medium-green;
      font: {
        weight: 500;
      }
    }
  }

  .link-and-button-place {
    @include xy-grid;
    flex-wrap: nowrap;
    // width: rem-calc(126);
  }

  .link-to-gold-page {
    font: {
      size: rem-calc(11);
      weight: 700;
    }
    text-transform: uppercase;

    .label-container {
      // transition: width 0.25s linear; Антон: не пойму что эта анимация должна анимировать

      .label {
        width: 100%;
      }
    }
  }

  .open-menu-button {
    width: rem-calc(24);
    margin: rem-calc(-1 0 0 15);

    img {
      width: rem-calc(24);
    }
  }
}

@include breakpoint(small only) {
  .layout-header {
    .link-and-button-place {
      justify-content: flex-end;
    }

    .link-to-gold-page {
      .text,
      .label-container {
        display: none;
        visibility: hidden;
      }

      .text-for-mobile {
        color: $medium-green;
        white-space: nowrap;
      }
    }
  }
}

@include breakpoint(medium up) {
  .layout-header {
    .content-container {
      height: rem-calc(64);
    }

    .logo {
      img {
        width: rem-calc(125);
      }
    }

    .link-to-gold-page {
      @include xy-grid;
      align-items: center;

      .text {
        color: $black;
        font: {
          size: rem-calc(12);
          weight: 400;
        }
      }

      .text-for-mobile {
        display: none;
        visibility: hidden;
      }

      .label-container {
        width: rem-calc(99);
        margin-left: rem-calc(5);
      }
    }
  }
}

@include breakpoint(large only) {
  .layout-header {
    .contact-phone {
      font-size: rem-calc(14);
    }
  }
}

@include breakpoint(1200px up) {
  .layout-header {
    .page-navigation.header__page-navigation {
      @include xy-cell-base(auto);
      @include xy-grid;
      display: flex !important;
      justify-content: center;

      ul {
        justify-content: space-between;
        // max-width: rem-calc(600);
      }
    }

    .contact-phone {
      margin-top: 0;
      margin-bottom: 0;
      color: inherit;

      &__value {
        color: $purple;
      }
    }

    .open-menu-button {
      display: none;
      visibility: hidden;
    }
  }
}

@include breakpoint(xlarge up) {
  .layout-header {
    // height: rem-calc(116);
    height: rem-calc(87);
    // transition: height 0.25s linear;

    .content-container {
      height: rem-calc(87);
    }

    .logo {
      margin-right: rem-calc(15);

      img {
        width: rem-calc(179);
      }
    }

    .link-to-gold-page {
      margin-left: rem-calc(15);

      .label-container {
        width: rem-calc(150);
        margin-right: rem-calc(-15);
      }
    }

    @at-root .header-repainted & {
      height: rem-calc(63);
      background: $white;
      box-shadow: 0 0 10px 0 rgba($black, 0.3);

      .content-container {
        height: rem-calc(63);
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
      }

      .logo {
        img {
          width: rem-calc(125);
        }
      }

      .link-to-gold-page {
        .label-container {
          width: rem-calc(99);
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2156";