@mixin universal-close-button($color: $black,
                               $icon-size: rem-calc(10),
                             $button-size: rem-calc(20)) {
  @include close-button;
  z-index: 1;
  height: $button-size;
  width: $button-size;
  color: $color;
  cursor: pointer;
  outline: none;

  .icon {
    @include absolute-center;
    height: $icon-size;
    width: $icon-size;
    fill: currentColor;
    transition: fill 0.25s ease-in-out;
  }

  &:focus {
    color: $color;
  }

  &:hover {
    color: scale-color($color, $lightness: -15%);
  }

  // NOTE: fix for event target
  * {
    pointer-events: none;
  }
}

;@import "sass-embedded-legacy-load-done:2111";