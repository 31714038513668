
.trustpilot-widget {
  margin: rem-calc(15);
}

@include breakpoint(medium up) {
  .trustpilot-widget {
    margin-left: 0;
  }
}


;@import "sass-embedded-legacy-load-done:2323";