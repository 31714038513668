@mixin std-button(
  $background: $medium-green,
  $background-hover: auto,
  $color: $white
) {
  @extend %with-cursor-without-outline;
  @include button(false, $background, $background-hover, $color);
  margin: 0;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);
  border-radius: rem-calc(6);
  font: {
    size: rem-calc(13);
    weight: 700;
  }

  text-transform: uppercase;

  &.disabled,
  &[disabled] {
    @include button-disabled;
  }
}

;@import "sass-embedded-legacy-load-done:2189";