@charset 'utf-8';

@import 'global/settings.scss';
@import 'foundation.scss';
@import 'global/styles.scss';

// Global styles
@include foundation-global-styles;
@include foundation-typography;
@import 'global/typography.scss';

/// Components
@import 'components/flash-messages';

/// Layout
@import 'layout/layout.scss';

@import 'animate.css/animate.css';
