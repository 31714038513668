@import 'global/mixins';

.layout-footer {
  @import 'footer/contact-phone';
  @import 'footer/get-offer';
  @import 'footer/page-navigation';
  @import 'footer/payment-systems';
  @import 'footer/social-networks';
  @import 'footer/trustpilot';

  &__container {
    // @include xy-grid-container(rem-calc(1720));
    @include xy-grid-container(rem-calc(1440));
  }

  &__header {
    padding: rem-calc(20 0 25);
    background: linear-gradient(90deg, #27272E 0%, #484854 100%);
  }

  &__footer {
    .layout-footer__container {
      @include xy-grid;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: rem-calc(5);

      &::before {
        @include horizontal-center;
        content: '';
        top: 0;
        height: rem-calc(1);
        width: calc(100% - 30px);
        background: #e9e9e9;
      }
    }
  }

  &__copyright {
    order: 3;
    text-align: center;
    color: $black;
    font: {
      size: rem-calc(13);
    }
  }

  &__disclaimer {
    margin-top: rem-calc(15);
    color: $dark-gray;
    font: {
      size: rem-calc(12);
    }
  }

  .logo {
    margin-bottom: rem-calc(15);
    text-align: center;
  }

  .hz {
    @include xy-grid;
    flex-direction: column-reverse;
  }

  .get-offer,
  .contact-phone,
  .sep {
    @include xy-cell-base;
    width: 100%;
  }

  .sep {
    text-align: center;
    color: $white;
    opacity: 0.7;
  }

  .trustpilot-and-social-networks {
    @include xy-grid;
    justify-content: center;
    align-items: center;
    order: 2;
    margin-bottom: rem-calc(25);
  }
}

@include breakpoint(small only) {
  .layout-footer {
    &__copyright {
      width: 100%;
    }

    .sep {
      margin: rem-calc(15 0);
    }
  }
}

@include breakpoint(425px up) {
  .layout-footer {
    .hz {
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
    }

    .get-offer,
    .contact-phone {
      width: auto;
    }

    .sep {
      width: rem-calc(70);
    }
  }
}

@include breakpoint(medium up) {
  .layout-footer {
    &__header {
      .layout-footer__container {
        @include xy-grid;
        justify-content: center
      }
    }

    &__copyright {
      margin-left: auto;
    }

    .logo {
      width: rem-calc(179);
      margin-bottom: 0;
      margin-right: rem-calc(30);
    }

    .hz {
      @include xy-grid;
      @include xy-cell-base(auto);
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      max-width: rem-calc(768);
    }

    .sep {
      @include xy-cell-base(auto);
      max-width: rem-calc(75);
    }

    &__footer {
      justify-content: space-between;
    }

    .trustpilot-and-social-networks {
      margin-bottom: 0;
    }
  }
}

@include breakpoint(large up) {
  .layout-footer {
    &__header {
      .layout-footer__container {
        justify-content: space-between;
      }
    }

    &__footer {
      .layout-footer__container {
        &::before {
          width: calc(100% - 50px);
        }
      }
    }

    .hz {
      max-width: 100%;
    }

    .sep {
      max-width: rem-calc(95);
    }

    .payment-systems {
      order: 2;
    }

    .trustpilot-and-social-networks {
      order: 1;
    }
  }
}

@include breakpoint(xlarge) {
  .layout-footer {
    .copyright {
      order: 2;
    }
    .payment-systems {
      order: 3;
    }
  }
}

;@import "sass-embedded-legacy-load-done:2178";