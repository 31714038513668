@import 'mixins/close-button';

.flash {
  &__message {
    width: 100%;
    max-width: $global-width;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &_global {
    @include xy-gutters($grid-margin-gutters, padding);
    position: absolute;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.flash-message {
  @include xy-gutters($grid-margin-gutters, padding);
  justify-content: space-between;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  padding-right: rem-calc(30);
  border: 1px solid;
  border-radius: 7px;
  color: $black;
  cursor: pointer;
  visibility: hidden;
  backdrop-filter: blur(3px);

  &.animate__animated {
    visibility: visible;
  }

  &_type_alert {
    border-color: rgba($alert-red, 0.6);
    background-color: rgba($alert-bg, 0.7);
  }

  &_type_notice {
    border-color: rgba($green, 0.6);
    background-color: rgba($success-bg, 0.7);
  }

  &__text {
    font: {
      weight: 500;
    }
  }

  &__dismiss-button {
    @include universal-close-button($black, rem-calc(25), rem-calc(25));
    top: rem-calc(8);
    right: rem-calc(5);
  }
}

;@import "sass-embedded-legacy-load-done:2090";