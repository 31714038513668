.page-navigation.footer__page-navigation {
  ul {
    @include menu-base;
    @include menu-direction(vertical);

    a {
      padding-right: 0;
      padding-left: 0;
      color: $medium-gray;
    }
  }

  .menu {
    margin-bottom: rem-calc(10);
    padding-top: rem-calc(10);

    &__item {
      margin-bottom: rem-calc(15);
    }

    &__link {
      display: block;
      padding: 0.7rem 0;
      color: $black;
      line-height: 1;
      font: {
        size: rem-calc(14);
        weight: 500;
      }
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .submenu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;

    &__link {
      display: block;
      padding: rem-calc(10 0);
      line-height: 1.3;
      font: {
        size: rem-calc(16);
      }
    }
  }
}

@include breakpoint(medium up) {
  .page-navigation.footer__page-navigation {
    .menu {
      @include xy-grid;
      margin: rem-calc(0 -10);

      &__item {
        @include xy-cell-base;
        width: calc(50% - 20px);
        margin: rem-calc(0 10 20);
      }
    }
  }
}

@include breakpoint(large up) {
  .page-navigation.footer__page-navigation {
    .menu {
      flex-wrap: nowrap;
      justify-content: space-between;

      &__item {
        width: auto;
      }
    }

    .submenu {
      display: flex;
    }
  }
}

@include breakpoint(xlarge up) {
  .page-navigation.footer__page-navigation {
    .menu {
      &__item {
        margin-bottom: rem-calc(30);
      }
    }

    .submenu {
      &__link {
        padding-top: rem-calc(5);
        padding-bottom: rem-calc(5);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2316";