.get-offer {
  text-align: center;

  &__label {
    margin-bottom: rem-calc(10);
    color: $white;
    font: {
      size: rem-calc(16);
      weight: 700;
    }
    text-transform: uppercase;
  }

  &__button {
    @include std-button;
    width: rem-calc(180);
  }
}

@include breakpoint(medium down) {
  .get-offer {
    &__label {
      display: none;
      visibility: hidden;
    }
  }
}

@include breakpoint(medium up) {
  .get-offer {
    @include xy-cell-base(auto);
    @include xy-grid;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    &__label {
      @include xy-cell-base(auto);
      min-width: rem-calc(175);
      max-width: max-content;
      margin-bottom: 0;
      text-align: left;
    }

    &__button {
      @include xy-cell-base;
      width: rem-calc(180);
      margin-left: rem-calc(15);
    }
  }
}

@include breakpoint(large up) {
  .get-offer {
    justify-content: flex-end;
  }
}

@include breakpoint(xlarge up) {
  .get-offer {
    &__label {
      font: {
        size: rem-calc(24);
      }
    }

    &__button {
      margin-left: rem-calc(45);
    }
  }
}

;@import "sass-embedded-legacy-load-done:2315";