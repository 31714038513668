html { scroll-behavior: smooth }

svg {
  fill: currentColor;
}

.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

;@import "sass-embedded-legacy-load-done:2055";