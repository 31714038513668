@import 'header';
@import 'footer';
@import '~layout/cookies_acceptance_component/cookies-acceptance-component';

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-header,
.layout-footer {
  @include xy-cell-base;
}

.layout-body {
  @include xy-cell-base(auto);
  margin-top: rem-calc(45);
  padding-bottom: rem-calc(50);
}

@include breakpoint(medium up) {
  .layout-body {
    margin-top: rem-calc(63);
    padding-bottom: rem-calc(80);
  }
}

@include breakpoint(large up) {
  .layout-body {
    margin-top: rem-calc(87);
  }
}

;@import "sass-embedded-legacy-load-done:2155";