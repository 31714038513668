.cookies-acceptance {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  transform: translateY(0);
  backface-visibility: hidden;
  color: #cccd;

  &--is-animating {
    transition-property: transform, opacity;
    transition: transform 0.3s ease 0s;
  }

  &__tab-button {
    position: relative;
    bottom: -4px;
    display: inline-block;
    margin-left: rem-calc(15);
    padding: rem-calc(10 30 6 14);
    background: linear-gradient(90deg, #27272E 0%, #484854 400%);
    border-top: 4px solid #51e346;
    border-radius: rem-calc(5 5 0 0);
    line-height: 1;
    color: #cccd;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;

    .icon {
      position: absolute;
      top: 50%;
      right: rem-calc(10);
      height: rem-calc(8);
      width: rem-calc(8);
      margin-top: rem-calc(-2);
      transition: opacity 0.3s ease-in-out;
    }

    .caret-down {
      opacity: 1;
      transform: rotate(90deg);
    }
  }

  &__content-container {
    @include xy-gutters($grid-margin-gutters, padding);
    background: linear-gradient(90deg, #27272E 0%, #484854 100%);
    border-top: 4px solid #51e346;
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);
    min-height: rem-calc(72);
    display:flex;
    align-items: center;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  &__secondary-button {
    @include std-button($background: #227e1b);
    margin-left: rem-calc(10);
    padding: rem-calc(5 15);
    color: $white;
  }

  &--collapsed {
    transform: translateY(100%);

    &__tab-button {
      .caret-down {
        opacity: 0;
      }
    }
  }
}

@include breakpoint(medium up) {
  .cookies-acceptance {
    &__tab-button {
      margin-left: rem-calc(15);
      font: {
        size: rem-calc(16);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:2501";