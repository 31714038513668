.page-navigation.header__page-navigation {
  display: none;

  ul {
    @include menu-base;

    li {
      font: {
        size: rem-calc(16);
      }
    }

    a {
      color: $medium-gray;
    }
  }
}

@include breakpoint(1199px down) {
  .page-navigation.header__page-navigation {
    @include off-canvas-basics;
    @include off-canvas-base;
    @include off-canvas-position(right);
    background: #27272e;

    ul {
      @include menu-direction(vertical);
      padding-top: rem-calc(40);

      li a {
        color: $white;
      }
    }
  }
}

@include breakpoint(1200px up) {
  .page-navigation.header__page-navigation {
    @include in-canvas;
  }
}

;@import "sass-embedded-legacy-load-done:2158";